"use client";

import _ from "lodash";
import { createContext, useContext, useState /*, useEffect*/ } from "react";

import {
  CN_USERS,
  CN_POSTS,
  CN_LABELS,
  CN_TESTOBJS,
  CN_IMAGES,
  CN_WAYS,
  CN_ACTS,
  CN_ITNS,
} from "@/dbGlobals";
import FC from "@/filterConstants";
import { hasProperty } from "@/utils/utilsCSAndSS";

const FS_KEY_TESTOBJS = "fsTestobjs";
const FS_KEY_ALL_CN = "fsAllCn";
const FS_KEY_USERS = "fsUsers";

// TODO: Ryan Wu, create a filter that is set up to show just users who are guides.
const FS_KEY_GUIDES = "fsGuides";
const FS_KEY_REQUESTS = "fsRequests";
const FS_KEY_WAYPOINTS = "fsWaypoints";
const FS_KEY_ADVENTURES = "fsAdventures";
const FS_KEY_ITINERARIES = "fsItineraries";
const FS_KEY_IMAGES = "fsImages";
const FS_KEY_FAV = "fsFavorites";
const FS_KEY_POSTS = "fsPosts";
const FS_KEY_HOME_GUIDES = "fsHomeGuides";
const FS_KEY_HOME_ACTS = "fsHomeActs";
const FS_KEY_HOME_POSTS = "fsHomePosts";
const FS_KEY_IMAGES_OWNED = "fsImagesOwned";
const FS_KEY_ADVENTURES_AND_ITINERARIES = "fsAdventuresAndItineraries";

const BASE_FILTERINFO = {
  search: "",
};

const BASE_SORTINFO = {
  created: { displayLabel: "Created Time", value: FC.SORT_NONE },
  modified: { displayLabel: "Modified Time", value: FC.SORT_NONE },
};

function createFilterSortObject({
  key,
  additionalFilterInfo = {},
  additionalSortInfo = {},
  propFilterAndSort = {},
}) {
  return {
    key,
    filterInfo: { ...BASE_FILTERINFO, ...additionalFilterInfo },
    sortInfo: { ...BASE_SORTINFO, ...additionalSortInfo },
    propFilterAndSort,
  };
}

const FS_ALL_CN = {
  key: FS_KEY_ALL_CN,
  filterInfo: {
    types: {
      labelsAndTestObjs: {
        displayLabel: "Labels And Test Objs",
        selected: false,
        filters: {},
        cNArray: [CN_LABELS, CN_TESTOBJS],
      },
      posts: {
        displayLabel: "Posts",
        selected: false,
        filters: {},
        cNArray: [CN_POSTS],
      },
      images: {
        displayLabel: "Images",
        selected: false,
        filters: {},
        cNArray: [CN_IMAGES],
      },
      user: {
        displayLabel: "User",
        selected: false,
        filters: {},
        cNArray: [CN_USERS],
      },
      waypoints: {
        displayLabel: "Waypoints",
        selected: false,
        filters: {},
        cNArray: [CN_WAYS],
      },
      itineraries: {
        displayLabel: "Itineraries",
        selected: false,
        filters: {},
        cNArray: [CN_ITNS],
      },
      acts: {
        displayLabel: "Adventures",
        selected: false,
        filters: {},
        cNArray: [CN_ACTS],
      },
    },
    labels: [],
    categories: [],
    priceRange: { min: undefined, max: undefined, currency: "NZD" },
    difficultyRange: { min: undefined, max: undefined },
    search: undefined,
    location: undefined,
    seasonal: undefined,
  },
  sortInfo: {
    created: { displayLabel: "Created Time", value: FC.SORT_NONE },
    modified: { displayLabel: "Modified Time", value: FC.SORT_NONE },
  },
  propFilterAndSort: {},
};

const FS_TESTOBJS = {
  key: FS_KEY_TESTOBJS,
  filterInfo: {
    search: undefined,
    seasonal: undefined,
    labels: [],
    categories: [],
    priceRange: { min: undefined, max: undefined, currency: "NZD" },
    difficultyRange: { min: undefined, max: undefined },
    location: undefined,
  },
  sortInfo: {
    created: { displayLabel: "Created Time", value: FC.SORT_NONE },
    modified: { displayLabel: "Modified Time", value: FC.SORT_NONE },
    displayName: { displayLabel: "Display Name", value: FC.SORT_NONE },
  },
  propFilterAndSort: {}, // This is where we will store the filter and sort values for the properties of the TestObj.
};

const FS_USERS = createFilterSortObject({
  key: FS_KEY_USERS,
  additionalFilterInfo: {
    groups: {
      admin: {
        displayLabel: "Admin",
        selected: false,
      },
      root: {
        displayLabel: "Root",
        selected: false,
      },
      guides: {
        displayLabel: "Guides",
        selected: false,
      },
      // public: {
      //   displayLabel: "Public",
      //   selected: false,
      // },
    },
    labels: [],
    categories: [],
    location: undefined,
  },
  additionalSortInfo: {
    displayName: { displayLabel: "Display Name", value: FC.SORT_NONE },
  },
});

const FS_POSTS = createFilterSortObject({
  key: FS_KEY_POSTS,
  additionalFilterInfo: {
    labels: [],
  },
  additionalSortInfo: {
    displayName: { displayLabel: "Display Name", value: FC.SORT_NONE },
  },
});

const FS_GUIDES = createFilterSortObject({
  key: FS_KEY_GUIDES,
  additionalFilterInfo: {
    groups: {
      guides: {
        displayLabel: "Guides",
        selected: true,
      },
      // public: {
      //   displayLabel: "Public",
      //   selected: false,
      // },
    },
    labels: [],
    categories: [],
    location: undefined,
  },
  additionalSortInfo: {
    displayName: { displayLabel: "Display Name", value: FC.SORT_NONE },
  },
});

const FS_REQUESTS = createFilterSortObject({
  key: FS_KEY_REQUESTS,
  additionalFilterInfo: {
    response: FC.FILTER_VALUE_RESPONSE_PENDING,
  },
});

const FS_WAYPOINTS = createFilterSortObject({
  key: FS_KEY_WAYPOINTS,
  additionalFilterInfo: {
    priceRange: { min: undefined, max: undefined, currency: "NZD" },
    difficultyRange: { min: undefined, max: undefined },
    location: undefined,
  },
  additionalSortInfo: {
    displayName: { displayLabel: "Display Name", value: FC.SORT_NONE },
  },
});

const FS_ADVENTURES = createFilterSortObject({
  key: FS_KEY_ADVENTURES,
  additionalFilterInfo: {
    priceRange: { min: undefined, max: undefined, currency: "NZD" },
    difficultyRange: { min: undefined, max: undefined },
    location: undefined,
    seasonal: undefined,
    labels: [],
    categories: [],
  },
  additionalSortInfo: {
    displayName: { displayLabel: "Display Name", value: FC.SORT_NONE },
  },
});

const FS_ITINERARIES = createFilterSortObject({
  key: FS_KEY_ITINERARIES,
  additionalFilterInfo: {
    priceRange: { min: undefined, max: undefined, currency: "NZD" },
    difficultyRange: { min: undefined, max: undefined },
    location: undefined,
    seasonal: undefined,
    labels: [],
    categories: [],
  },
  additionalSortInfo: {
    displayName: { displayLabel: "Display Name", value: FC.SORT_NONE },
  },
});

const FS_IMAGES = createFilterSortObject({
  key: FS_KEY_IMAGES,
  additionalFilterInfo: {
    owner: {
      me: {
        displayLabel: "Me",
        selected: true,
      },
      others: {
        displayLabel: "Others",
        selected: false,
      },
    },
    labels: [],
    categories: [],
    location: undefined,
  },
  additionalSortInfo: {
    displayName: { displayLabel: "Display Name", value: FC.SORT_NONE },
  },
});

const FS_IMAGES_OWNED = createFilterSortObject({
  key: FS_KEY_IMAGES_OWNED,
  additionalFilterInfo: {
    owner: {
      me: {
        displayLabel: "Me",
        selected: true,
      },
    },
    labels: [],
    categories: [],
    location: undefined,
  },
  additionalSortInfo: {
    displayName: { displayLabel: "Display Name", value: FC.SORT_NONE },
  },
});

const FS_FAV = createFilterSortObject({
  key: FS_KEY_FAV,
  additionalFilterInfo: {
    types: {
      waypoints: {
        displayLabel: "Waypoints",
        selected: false,
        filters: {},
        cNArray: [CN_WAYS],
      },
      itineraries: {
        displayLabel: "Itineraries",
        selected: false,
        filters: {},
        cNArray: [CN_ITNS],
      },
      acts: {
        displayLabel: "Adventures",
        selected: false,
        filters: {},
        cNArray: [CN_ACTS],
      },
    },
    labels: [],
    categories: [],
    priceRange: { min: undefined, max: undefined, currency: "NZD" },
    difficultyRange: { min: undefined, max: undefined },
    location: undefined,
    seasonal: undefined,
  },
  additionalSortInfo: {
    displayName: { displayLabel: "Display Name", value: FC.SORT_NONE },
  },
});

const FS_HOME_GUIDES = {
  key: FS_KEY_HOME_GUIDES,
  filterInfo: {
    labels: [],
    categories: [],
    search: "",
    location: undefined,
  },
};

const FS_HOME_POSTS = {
  key: FS_KEY_HOME_POSTS,
  filterInfo: {
    labels: [],
    search: "",
    location: undefined,
  },
};

const FS_HOME_ACTS = {
  key: FS_KEY_HOME_ACTS,
  filterInfo: {
    labels: [],
    categories: [],
    search: "",
    location: undefined,
  },
};

const FS_ADVENTURES_AND_ITINERARIES = createFilterSortObject({
  key: FS_KEY_ADVENTURES_AND_ITINERARIES,
  additionalFilterInfo: {
    types: {
      itineraries: {
        displayLabel: "Itineraries",
        selected: false,
        filters: {},
        cNArray: [CN_ITNS],
      },
      acts: {
        displayLabel: "Adventures",
        selected: false,
        filters: {},
        cNArray: [CN_ACTS],
      },
    },
    labels: [],
    categories: [],
    priceRange: { min: undefined, max: undefined, currency: "NZD" },
    difficultyRange: { min: undefined, max: undefined },
    location: undefined,
    seasonal: undefined,
  },
});


// NOTE: We will want different defaults, depending upon the key.
// For example, when sorting the list of Users when selecting "friends"
// we should sort so currently selected Users are at the top of the list.
//
const DEFAULT_FILTER_AND_SORT_STATES = {
  [FS_KEY_ALL_CN]: FS_ALL_CN,
  [FS_KEY_TESTOBJS]: FS_TESTOBJS,
  [FS_KEY_USERS]: FS_USERS,
  [FS_KEY_REQUESTS]: FS_REQUESTS,
  [FS_KEY_WAYPOINTS]: FS_WAYPOINTS,
  [FS_KEY_ADVENTURES]: FS_ADVENTURES,
  [FS_KEY_ITINERARIES]: FS_ITINERARIES,
  [FS_KEY_IMAGES]: FS_IMAGES,
  [FS_KEY_FAV]: FS_FAV,
  [FS_KEY_GUIDES]: FS_GUIDES,
  [FS_KEY_POSTS]: FS_POSTS,
  [FS_KEY_HOME_GUIDES]: FS_HOME_GUIDES,
  [FS_KEY_HOME_POSTS]: FS_HOME_POSTS,
  [FS_KEY_HOME_ACTS]: FS_HOME_ACTS,
  [FS_KEY_IMAGES_OWNED]: FS_IMAGES_OWNED,
  [FS_KEY_ADVENTURES_AND_ITINERARIES]: FS_ADVENTURES_AND_ITINERARIES,
};

// This value should never actually be seen.
const DEFAULT_FILTER_AND_SORT = {};

const FilterAndSortContext = createContext(DEFAULT_FILTER_AND_SORT);

function FilterAndSortProvider({ children }) {
  const [filterAndSortStates, setFilterAndSortStates] = useState(
    DEFAULT_FILTER_AND_SORT_STATES
  );

  /**
   * The "key" to get the filter and sort values for a page is a
   * few of the searchParams concatenated together.
   * TODO: Figure out which values we want to use.  Or, perhaps we
   * want to add a unique key to the searchParams, and use that.
   *
   * @param {string} cN - The collection name if we are displaying a homogeneous list
   * of objects from the database.  NOT a list of selectable objects.
   *
   * @param {string} propName - If we are displaying a list of objects that
   * are coming from an array property of an object, then this will be set.
   * For example, if we are displaying a User object's "friends" list, this
   * would be set to "friends", (assuming the name of the property is actually
   * "friends").
   *
   * @param {string} propCn - If we are displaying a homogeneous list of objects
   * that are coming from an array property of an object, then this will be set
   * to the type of objects in the list.
   * For example, if we are displaying a User object's "friends" list, propCn
   * would be "users".
   */
  function getFilterAndSortState(key, isSuperUser = false) {
    log.trace("getFilterAndSortState(), key ", key);
    let filterAndSortState = _.cloneDeep(filterAndSortStates[key]);

    if (isSuperUser && !hasProperty(filterAndSortState.filterInfo, "status")) {
      filterAndSortState = {
        ...filterAndSortState,
        filterInfo: {
          ...filterAndSortState.filterInfo,
          status: null,
        },
      };
    }

    if (!filterAndSortState) {
      log.bug(
        "In getFilterAndSortState(), need to create default value for key(" +
          key +
          ")"
      );
      alert("Bad key passed to getFilterAndSortState, key " + key);
    }
    log.trace("getFilterAndSortState(), returning ", filterAndSortState);
    return filterAndSortState;
  }

  function setFilterAndSortState(filterAndSortState) {
    log.trace(
      "In FilterAndSortContext setFilterAndSortState(), filterAndSortState ",
      filterAndSortState
    );
    // Create a new object so React will recognize the value
    // has changed.
    const newFilterAndSortStates = _.cloneDeep(filterAndSortStates);
    newFilterAndSortStates[filterAndSortState.key] =
      _.cloneDeep(filterAndSortState);
    setFilterAndSortStates(newFilterAndSortStates);
    log.trace(
      "In FilterAndSortContext setFilterAndSortState(), newFilterAndSortStates ",
      newFilterAndSortStates
    );
  }

  function getDefaultFilterAndSortState(propFsKey, isSuperUser) {
    let filterAndSortState = _.cloneDeep(
      DEFAULT_FILTER_AND_SORT_STATES[propFsKey]
    );

    if (isSuperUser) {
      filterAndSortState = {
        ...filterAndSortState,
        filterInfo: {
          ...filterAndSortState.filterInfo,
          status: null,
        },
      };
    }

    return filterAndSortState;
  }

  function getDefaultFilterAndSortStateWithSelect(propFsKey, isSuperUser) {
    let newFilterAndSortStates = _.cloneDeep(
      DEFAULT_FILTER_AND_SORT_STATES[propFsKey]
    );
    if (hasProperty(newFilterAndSortStates, "sortInfo")) {
      newFilterAndSortStates.sortInfo[FC.FILTER_SELECTED] =
        FC.SORT_SELECTED_INFO;
    }
    if (hasProperty(newFilterAndSortStates, "filterInfo")) {
      newFilterAndSortStates.filterInfo[FC.FILTER_SELECTED] = {
        displayLabel: "By Selected",
        value: FC.FILTER_VALUE_NONE,
      };
    }

    if (isSuperUser) {
      newFilterAndSortStates = {
        ...newFilterAndSortStates,
        filterInfo: {
          ...newFilterAndSortStates.filterInfo,
          status: null,
        },
      };
    }

    return newFilterAndSortStates;
  }

  const filterAndSort = {
    filterAndSortStates,
    setFilterAndSortState, // Set one filterAndSortState
    getFilterAndSortState, // Get one filterAndSortState
    getDefaultFilterAndSortState, // Reset one filterAndSortState
    getDefaultFilterAndSortStateWithSelect, // Reset one filterAndSortState with select
  };

  const component = (
    /*
    <ErrorBoundary
      FallbackComponent={MyFallback}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
    */
    <FilterAndSortContext.Provider value={filterAndSort}>
      {children}
    </FilterAndSortContext.Provider>
    /*
    </ErrorBoundary>
    */
  );
  return component;
}

function useFilterAndSort() {
  const filterAndSort = useContext(FilterAndSortContext);
  return filterAndSort;
}

export {
  FilterAndSortProvider,
  useFilterAndSort,
  FS_KEY_ALL_CN,
  FS_KEY_TESTOBJS,
  FS_KEY_USERS,
  FS_KEY_GUIDES,
  FS_KEY_REQUESTS,
  FS_KEY_WAYPOINTS,
  FS_KEY_ADVENTURES,
  FS_KEY_ITINERARIES,
  FS_KEY_IMAGES,
  FS_KEY_FAV,
  FS_KEY_POSTS,
  FS_KEY_HOME_GUIDES,
  FS_KEY_HOME_POSTS,
  FS_KEY_HOME_ACTS,
  FS_KEY_IMAGES_OWNED,
  FS_KEY_ADVENTURES_AND_ITINERARIES,
};

import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/vercel/path0/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["DbInfoProvider"] */ "/vercel/path0/src/app/dbInfoContext.js");
;
import(/* webpackMode: "eager", webpackExports: ["FilterAndSortProvider"] */ "/vercel/path0/src/app/filterAndSortContext.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fortawesome/fontawesome-free/css/all.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["IntlProviderWrapper"] */ "/vercel/path0/src/app/IntlProviderWrapper.js");
;
import(/* webpackMode: "eager", webpackExports: ["SettingsProvider"] */ "/vercel/path0/src/app/settingsContext.js");
;
import(/* webpackMode: "eager", webpackExports: ["UserMongoProvider"] */ "/vercel/path0/src/app/userMongoContextJin.js");

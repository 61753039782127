"use client";

import { createContext, useContext, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import MyFallback from "@/components/myFallback";

const defaultSettings = {
  className: "", // debug

  // Currently "Signed In" user.
  curUserId: 0,

  // Map (No longer used)
  markerIconDimensions: { width: 48, height: 48 },

  // Map
  markerDimensions: { width: "3rem" },
  markerExpandedDimensions: { width: "15rem" },

  // Object Pages
  //cardWidthPx: 250,
  //badgesDimensions: { width: "8rem" },
  //actsDimensions: { width: "8rem" },
  //usersDimensions: { width: "8rem", height: "8rem" },

  // User Profile
  profileImageDimensions: { width: "8rem" },
  recommendedGuidesRowDimensions: { width: "6rem", height: "6rem" },
  myBadgesNumCols: 5,
  myActsDimensions: { width: "3rem" },
  showLandscapeCards: true,

  userProfileMenuItem: "timeline",
  //showSidebar: true,

  // Move these values elsewhere.
  // They are not settings, but really part of the UI's state.
  obj: undefined,
  //expandedId: null,
};

const SettingsContext = createContext(defaultSettings);

function SettingsProvider({ children }) {
  const [curUserId, setCurUserId] = useState(defaultSettings.curUserId);
  const [markerIconDimensions, setMarkerIconDimensions] = useState(
    defaultSettings.markerIconDimensions
  );
  const [markerDimensions, setMarkerDimensions] = useState(
    defaultSettings.markerDimensions
  );
  const [markerExpandedDimensions, setMarkerExpandedDimensions] = useState(
    defaultSettings.markerExpandedDimensions
  );
  const [profileImageDimensions, setProfileImageDimensions] = useState(
    defaultSettings.profileImageDimensions
  );
  const [recommendedGuidesRowDimensions, setRecommendedGuidesRowDimensions] =
    useState(defaultSettings.recommendedGuidesRowDimensions);
  const [myBadgesNumCols, setMyBadgesNumCols] = useState(
    defaultSettings.myBadgesNumCols
  );
  const [myActsDimensions, setMyActsDimensions] = useState(
    defaultSettings.myActsDimensions
  );
  const [showLandscapeCards, setShowLandscapeCards] = useState(
    defaultSettings.showLandscapeCards
  );
  const [className, setClassName] = useState(defaultSettings.className);
  //const [cardWidthPx, setCardWidthPx] = useState(defaultSettings.cardWidthPx);
  const [userProfileMenuItem, setUserProfileMenuItem] = useState(
    defaultSettings.userProfileMenuItem
  );
  //const [showSidebar, setShowSidebar] = useState(defaultSettings.showSidebar);
  const [obj, setObj] = useState(defaultSettings.obj);
  //const [expandedId, setExpandedId] = useState(defaultSettings.expandedId);

  const settings = {
    curUserId,
    setCurUserId,
    markerIconDimensions,
    setMarkerIconDimensions,
    markerDimensions,
    setMarkerDimensions,
    markerExpandedDimensions,
    setMarkerExpandedDimensions,
    profileImageDimensions,
    setProfileImageDimensions,
    recommendedGuidesRowDimensions,
    setRecommendedGuidesRowDimensions,
    myBadgesNumCols,
    setMyBadgesNumCols,
    myActsDimensions,
    setMyActsDimensions,
    showLandscapeCards,
    setShowLandscapeCards,
    className,
    setClassName,
    //cardWidthPx,
    //setCardWidthPx,
    userProfileMenuItem,
    setUserProfileMenuItem,
    //showSidebar,
    //setShowSidebar,

    // Not a setting.  The current obj being displayed.
    obj,
    setObj,

    // Not a setting.  The current obj being displayed.
    /*
    expandedId,
    setExpandedId,
    */
  };

  const component = (
    /*
    <ErrorBoundary
      FallbackComponent={MyFallback}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
        console.log("details ", details);
      }}
    >
    */
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
    /*
    </ErrorBoundary>
    */
  );
  return component;
}

function useSettings() {
  const settings = useContext(SettingsContext);
  return settings;
}

export { SettingsProvider, useSettings };

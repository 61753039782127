"use client";

import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import enMessages from "@/locales/en.json";

const messages = {
  en: enMessages,
};

// import { FormattedMessage } from "react-intl";
// Usage 1. Simple
// <FormattedMessage id="plan_your_next_trip_on_the_map" />
// Usage 2. Placeholder
// const destination = "trending destinations";
// <FormattedMessage
// id="discover_trending_destinations"
// values={{ destination }} // Pass the placeholder value
// />

// To switch the language, refer to LanguageSwitcher.js in components/i18n

export const IntlProviderWrapper = ({ children }) => {
  const [locale, setLocale] = useState("en"); // Default language is English

  const switchLanguage = (newLocale) => {
    setLocale(newLocale);
  };

  return (
    <IntlProvider 
      locale={locale} 
      messages={messages[locale]}
      defaultLocale="en"
      defaultMessages={messages.en} // Use en translations as default in case of some translations are missing in other languages
    >
      {children}
    </IntlProvider>
  );
};